<script setup lang="ts">
import type {
  CmsElementImage,
} from "@shopware-pwa/composables-next";
import { computed } from "vue";

const props = defineProps<{
  content: any;
}>();
const items = computed(() => props?.content?.data?.sliderItems);

</script>
<template>
  <div class="py-12">
    <div class="brand-image__container">
      <template v-for="image of items" :key="image.media.url">

        <div class="brand-image__element">
        <CmsElementImage
            :content="{
              data: image,
              config: { }
          } as unknown as CmsElementImage"
            :containerClasses="['cms-element-image', 'position-relative']"
            :additionalContainerStyles="{
              height: '64px'
            }"
        />
        </div>
      </template>
    </div>
  </div>

</template>

<style scoped>

.brand-image__container {
  display: flex;
  gap: 10px;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
}

@media screen and (min-width: 1024px) {
  .brand-image__container {
    justify-content: space-between;
  }
}

</style>
